import { AxiosError } from 'axios';
import { createContext, useReducer } from 'react';
import { Wizard } from '../../../interfaces/Wizard.interface';
import httpClient from '../../../services/httpClient.service';
import {
  DELETE_WIZARDS_ERROR,
  DELETE_WIZARDS_SUCCESS,
  GET_WIZARDS_ERROR,
  GET_WIZARDS_SUCCESS,
  GET_WIZARD_ERROR,
  GET_WIZARD_SUCCESS,
  LOADING,
  POST_WIZARDS_ERROR,
  POST_WIZARDS_SUCCESS,
  PUT_WIZARDS_ERROR,
  PUT_WIZARDS_SUCCESS,
} from '../../types';
import WizardReducer from './WizardReducer';

// CONTEXT
interface IWizardContext {
  isLoading: boolean;
  isError: boolean;
  wizards: Wizard[];
  selectedWizard: Wizard | null;
  getWizards(): void;
  getWizard(id: string): void;
  updateWizard(id: string, wizard: object): void;
  createWizard(wizard: object): void;
  deleteWizard(id: string): void;
}

const defaultState: IWizardContext = {
  isLoading: false,
  isError: false,
  wizards: [],
  selectedWizard: null,
  getWizards: () => {},
  getWizard: () => {},
  updateWizard: () => {},
  createWizard: () => {},
  deleteWizard: () => {},
};

export const WizardContext = createContext<IWizardContext>(defaultState);

// STATE
const WizardState = (props: any) => {
  const initialState = defaultState;

  const [state, dispatch] = useReducer(WizardReducer, initialState);

  const getWizards = async () => {
    dispatch({ type: LOADING });
    try {
      const res = await httpClient().get('/wizards/all');
      dispatch({ type: GET_WIZARDS_SUCCESS, payload: res.data });
    } catch (error) {
      await handleRequestError(error, GET_WIZARDS_ERROR);
    }
  };

  const getWizard = async (id: string) => {
    dispatch({ type: LOADING });
    try {
      const res = await httpClient().get(`/wizards/${id}`);
      dispatch({ type: GET_WIZARD_SUCCESS, payload: res.data });
    } catch (error) {
      await handleRequestError(error, GET_WIZARD_ERROR);
    }
  };

  const updateWizard = async (id: string, newData: object) => {
    dispatch({ type: LOADING });
    try {
      console.log(newData);

      const res = await httpClient().put(`/wizards/${id}`, newData);
      dispatch({ type: PUT_WIZARDS_SUCCESS, payload: res.data });
    } catch (error: any) {
      // TODO add custom alerts in forms
      const err = error as AxiosError<any, any>;
      alert(err.response?.data.message);
      await handleRequestError(error, PUT_WIZARDS_ERROR);
    }
  };

  const createWizard = async (wizard: object) => {
    dispatch({ type: LOADING });
    try {
      const res = await httpClient().post('/wizards', wizard);
      dispatch({ type: POST_WIZARDS_SUCCESS, payload: res.data });
    } catch (error: any) {
      // TODO add custom alerts in forms
      const err = error as AxiosError<any, any>;
      alert(err.response?.data.message);
      await handleRequestError(error, POST_WIZARDS_ERROR);
    }
  };

  const deleteWizard = async (id: string) => {
    dispatch({ type: LOADING });
    try {
      await httpClient().delete(`/wizards/${id}`);
      dispatch({ type: DELETE_WIZARDS_SUCCESS, payload: id });
    } catch (error) {
      await handleRequestError(error, DELETE_WIZARDS_ERROR);
    }
  };

  const handleRequestError = (error: any, type: string) => {
    if (error instanceof AxiosError) {
      if (error.response?.status === 401) {
        localStorage.removeItem('nano-admin-user-auth-token');
        window.location.href = '/';
      }
    }

    dispatch({ type, payload: error });
  };

  return (
    <WizardContext.Provider
      value={{
        isLoading: state.isLoading,
        isError: state.isError,
        wizards: state.wizards,
        selectedWizard: state.selectedWizard,
        getWizard,
        getWizards,
        updateWizard,
        createWizard,
        deleteWizard,
      }}
    >
      {props.children}
    </WizardContext.Provider>
  );
};

export default WizardState;
