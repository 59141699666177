import axios from 'axios';

import { API_URL } from '../config';

const httpClient = () => {
  let headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  };
  const authToken = localStorage.getItem('nano-admin-user-auth-token');
  if (authToken && authToken !== '') headers = Object.assign(headers, { 'Authorization': `Bearer ${authToken}` });

  return axios.create({
    baseURL: API_URL,
    headers,
    // adapter:
  });
};

export default httpClient;
