import { useContext, useEffect, useState } from 'react';
import WizardsTable from './WizardsTable';
import { WizardContext } from '../../../store/context/wizard/WizardState';
import WizardModal from './WizardModal';
import { Wizard, WizardStepIdentifiers } from '../../../interfaces/Wizard.interface';
import { Button } from '@mui/material';

const Wizards = () => {
  // CONTEXT
  const { getWizards, wizards, isLoading, updateWizard, createWizard } = useContext(WizardContext);

  const [selectedWizard, setSelectedWizard] = useState<Wizard | undefined | null>(null);
  const [wizardDialogActive, setWizardDialogActive] = useState(false);

  useEffect(() => {
    getWizards();
    // eslint-disable-next-line
  }, []);

  const handleEditWizard = (wizardId: string) => {
    const wizard = wizards.find((wizard) => wizard.id === wizardId);
    setSelectedWizard(wizard);
    setWizardDialogActive(true);
  };

  const handleCreateWizard = () => {
    const wizard: Wizard = {
      default: false,
      enabled: true,
      name: '',
      steps: [
        {
          position: 1,
          required: true,
          type: WizardStepIdentifiers.EMAIL,
        },
      ],
    };
    setSelectedWizard(wizard);
    setWizardDialogActive(true);
  };

  const handleModalClose = () => {
    setSelectedWizard(null);
    setWizardDialogActive(false);
  };

  const handleSubmit = async (wizard: Wizard, id?: string) => {
    if (id) {
      //save update
      await updateWizard(id, wizard);
    } else {
      await createWizard(wizard);
    }
    await getWizards();
    setSelectedWizard(null);
    setWizardDialogActive(false);
  };

  return (
    <>
      <div style={{ width: '98%', margin: '0 auto' }}>
        <Button variant="contained" onClick={handleCreateWizard}>
          Create new wizard
        </Button>
      </div>
      <WizardsTable wizards={wizards} loading={isLoading} handleEditWizard={handleEditWizard} />
      <WizardModal
        selectedWizard={selectedWizard}
        open={wizardDialogActive}
        handleClose={handleModalClose}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default Wizards;
