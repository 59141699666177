import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CancelIcon from '@mui/icons-material/Cancel';

import { useEffect, useState } from 'react';

export type FilterRowProps = {
  variants: Array<any>;
  position: number;
  selectedVariantId: number | null;
  filterName: string;
  handleRemoveFilter: (position: number) => void;
  handleFilterVariantChange: (variantId: string, position: number) => void;
  handleFilterNameChange: (name: string, position: number) => void;
};

const CustomerFilterRow = ({
  variants,
  position,
  selectedVariantId,
  filterName,
  handleRemoveFilter,
  handleFilterVariantChange,
  handleFilterNameChange,
}: FilterRowProps) => {
  const [variantId, setVariantId] = useState<number>(0);
  const [name, setName] = useState<string>('');

  useEffect(() => {
    if (selectedVariantId) setVariantId(selectedVariantId);
  }, [selectedVariantId]);

  useEffect(() => {
    if (filterName) setName(filterName);
  }, [filterName]);

  const handleSelectionChange = (event: SelectChangeEvent) => {
    handleFilterVariantChange(event.target.value, position);
    setVariantId(parseInt(event.target.value));
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFilterNameChange(event.target.value, position);
    setName(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 240 }}>
        <TextField
          autoFocus
          margin="dense"
          id={`filter-${position}-name`}
          label={`Filter #${position} name`}
          type="text"
          fullWidth
          variant="standard"
          value={name}
          onChange={handleNameChange}
        />
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <InputLabel id={`${selectedVariantId}-${position}-label`}>Variant</InputLabel>
        <Select
          labelId={`${selectedVariantId}-${position}-label`}
          id={`${selectedVariantId}-${position}-filter-select`}
          value={variantId.toString()}
          label="Filter"
          onChange={handleSelectionChange}
        >
          <MenuItem value={0}>
            <em>None</em>
          </MenuItem>
          {variants.map((variant) => (
            <MenuItem key={variant.id} value={variant.id}>
              {variant.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 20 }}>
        {position !== 1 && (
          <IconButton aria-label="add-filter" title="Add new filter" onClick={() => handleRemoveFilter(position)}>
            <CancelIcon />
          </IconButton>
        )}
      </FormControl>
    </div>
  );
};

export default CustomerFilterRow;
