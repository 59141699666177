export const API_HOST = process.env.REACT_APP_API_HOST;
export const API_VERSION = process.env.REACT_APP_API_VERSION;
export const APP_DOMAIN = process.env.REACT_APP_APP_DOMAIN;

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export const API_URL = `${API_HOST}/api/${API_VERSION}/`;
