import { useContext } from 'react';
import { AuthContext } from '../../store/context/auth/AuthState';

import Spinner from '../common/spinner/Spinner';

import { Link } from 'react-router-dom';

const PrivateLayout = ({ children }: PrivateLayoutProps) => {
  // CONTEXT
  const { isLoading } = useContext(AuthContext);

  // useEffect(() => {
  //   if (isAuthenticated && !isLoading) {
  //     AuthFN.verify();
  //   }
  //   // eslint-disable-next-line
  // }, []);

  return (
    <>
      <header className="adminHeader">
        <div className="adminHeaderContainer">
          <img
            src="https://nano-home.github.io/nano/assets/brand/logotype-E9AA5F.svg"
            alt="NANO Logo"
            className="adminHeaderLogo"
          />
          <b className="adminPortalCaption">
            | Admin
            <span> Portal</span>
          </b>
          <Link to="/logout" title="Logout" className="adminHeaderLogout">
            <i className="mdi mdi-logout"></i>
          </Link>
        </div>
      </header>
      <section className="adminContent">{!isLoading ? children : <Spinner />}</section>
    </>
  );
};

export default PrivateLayout;

type PrivateLayoutProps = {
  children: any;
};
