import { LOGIN, LOGOUT, LOADING, AUTH_ERROR, AUTH_SUCCESS } from "../../types";

const AuthReducer = (state: any, action: any) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN:
      return {
        ...state,
        isLoading: true,
        isError: false,
        userData: {},
      };
    case LOGOUT:
      window.location.href = '/';
      return {
        ...state,
        isError: false,
        messageError: '',
        isLoading: false,
        isAuthenticated: false,
        userData: {},
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        userData: action.payload,
        isError: false,
      };
    case AUTH_ERROR:
      return {
        ...state,
        isError: true,
        messageError: action.payload,
        isLoading: false,
        isAuthenticated: false,
        userData: {},
      };
    default:
      return state;
  }
};

export default AuthReducer;
