import { Route, Routes } from 'react-router-dom';

import ErrorPage from '../components/pages/ErrorPage';
import PrivateRoute from './PrivateRoute';
import Login from './Login';
import Customers from '../components/pages/customers/Customers';
import Logout from './Logout';
import PasswordReset from './PasswordReset';
import Home from '../components/pages/Home';
import Wizards from '../components/pages/wizards/Wizards';

const Root = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/password-reset" element={<PasswordReset />} />
      <Route path="/logout" element={<Logout />} />

      <Route
        path="/customers"
        element={
          <PrivateRoute>
            <Customers />
          </PrivateRoute>
        }
      />

      <Route
        path="/wizards"
        element={
          <PrivateRoute>
            <Wizards />
          </PrivateRoute>
        }
      />

      <Route
        path="/"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />

      <Route path="*" element={<ErrorPage errorType={404} />} />
    </Routes>
  );
};

export default Root;
