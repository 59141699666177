export const LOADING = 'LOADING';

// AUTHENTICATION
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';

// SUBSCRIPTION
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_ERROR = 'UPDATE_SUBSCRIPTION_ERROR';
export const FETCH_ALL_VARIANTS_SUCCESS = 'FETCH_ALL_VARIANTS_SUCCESS';
export const FETCH_ALL_VARIANTS_ERROR = 'FETCH_ALL_VARIANTS_ERROR';
export const FETCH_PREVIEW_SUBSCRIPTION_SUCCESS = 'FETCH_PREVIEW_SUBSCRIPTION_SUCCESS';
export const FETCH_PREVIEW_SUBSCRIPTION_ERROR = 'FETCH_PREVIEW_SUBSCRIPTION_ERROR';
export const CLEAR_SUBSCRIPTION_PREVIEW = 'CLEAR_SUBSCRIPTION_PREVIEW';

// CUSTOMERS
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_ERROR = 'GET_CUSTOMERS_ERROR';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_ERROR = 'GET_CUSTOMER_ERROR';
export const PUT_CUSTOMERS_SUCCESS = 'PUT_CUSTOMERS_SUCCESS';
export const PUT_CUSTOMERS_ERROR = 'PUT_CUSTOMERS_ERROR';
export const DELETE_CUSTOMERS_SUCCESS = 'DELETE_CUSTOMERS_SUCCESS';
export const DELETE_CUSTOMERS_ERROR = 'DELETE_CUSTOMERS_ERROR';

// WIZARDS
export const GET_WIZARDS_SUCCESS = 'GET_WIZARDS_SUCCESS';
export const GET_WIZARDS_ERROR = 'GET_WIZARDS_ERROR';
export const GET_WIZARD_SUCCESS = 'GET_WIZARD_SUCCESS';
export const GET_WIZARD_ERROR = 'GET_WIZARD_ERROR';
export const PUT_WIZARDS_SUCCESS = 'PUT_WIZARDS_SUCCESS';
export const PUT_WIZARDS_ERROR = 'PUT_WIZARDS_ERROR';
export const POST_WIZARDS_SUCCESS = 'POST_WIZARDS_SUCCESS';
export const POST_WIZARDS_ERROR = 'POST_WIZARDS_ERROR';
export const DELETE_WIZARDS_SUCCESS = 'DELETE_WIZARDS_SUCCESS';
export const DELETE_WIZARDS_ERROR = 'DELETE_WIZARDS_ERROR';
