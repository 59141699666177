import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../store/context/auth/AuthState';

import Layout from '../components/layout/PublicLayout';
import LoginForm from '../components/pages/login/LoginForm';
import Spinner from '../components/common/spinner/Spinner';

const Login = () => {
  const navigate = useNavigate();

  // CONTEXT
  const { isAuthenticated, isLoading } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthenticated) navigate('/');

    // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <Layout>
      <div>
        <div>{isLoading ? <Spinner /> : <LoginForm />}</div>
      </div>
    </Layout>
  );
};

export default Login;
