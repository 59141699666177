import { useContext, useEffect } from 'react';
import Spinner from '../components/common/spinner/Spinner';
import { AuthContext } from '../store/context/auth/AuthState';

const Logout = () => {
  // CONTEXT
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    logout();
    // eslint-disable-next-line
  }, []);

  return <Spinner />;
};

export default Logout;
