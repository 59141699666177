import { AxiosError } from 'axios';
import { createContext, useReducer } from 'react';
import httpClient from '../../../services/httpClient.service';
import {
  LOADING,
  SET_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_ERROR,
  UPDATE_SUBSCRIPTION_SUCCESS,
  FETCH_ALL_VARIANTS_SUCCESS,
  FETCH_ALL_VARIANTS_ERROR,
  FETCH_PREVIEW_SUBSCRIPTION_SUCCESS,
  FETCH_PREVIEW_SUBSCRIPTION_ERROR,
  CLEAR_SUBSCRIPTION_PREVIEW,
} from '../../types';
import SusbcriptionReducer from './SubscriptionReducer';

// CONTEXT
interface ISubscriptionContext {
  isLoading: boolean;
  isError: boolean;
  errorDetail: string;
  subscription: object;
  subscriptionPreview: any;
  variants: Array<object>;
  fetchPreviewSubscription(data: object): void;
  fetchAllVariants(): void;
  setSubscription(data: object): void;
  updateSubscription(object: object): any;
  clearSubscriptionPreview(): void;
}

const defaultState: ISubscriptionContext = {
  isLoading: false,
  isError: false,
  errorDetail: '',
  subscription: {},
  variants: [],
  subscriptionPreview: {},
  fetchPreviewSubscription: () => {},
  fetchAllVariants: () => {},
  setSubscription: () => {},
  updateSubscription: (): any => {},
  clearSubscriptionPreview: () => {},
};

export const SubscriptionContext = createContext<ISubscriptionContext>(defaultState);

// STATE
const SubscriptionState = (props: any) => {
  const initialState = defaultState;

  const [state, dispatch] = useReducer(SusbcriptionReducer, initialState);

  const setSubscription = (data: object) => {
    dispatch({ type: SET_SUBSCRIPTION, payload: data });
  };

  const updateSubscription = async (data: object) => {
    dispatch({ type: LOADING });
    try {
      const res = await httpClient().put('/customers/subscription', data);

      // Wait for customer.lastSubscription.subscribedAt to be set by Stripe webhook
      // consider updating to websockets communication or similar to avoid failures on this unreliable setTimeout
      return await new Promise((resolve) => {
        setTimeout(() => {
          dispatch({ type: UPDATE_SUBSCRIPTION_SUCCESS, payload: res.data });
          resolve({ success: true });
        }, 2000);
      });
    } catch (error) {
      await handleRequestError(error, UPDATE_SUBSCRIPTION_ERROR);
      return { success: false };
    }
  };

  const fetchAllVariants = async () => {
    dispatch({ type: LOADING });
    try {
      const res = await httpClient().get('/products/variants/all');
      dispatch({ type: FETCH_ALL_VARIANTS_SUCCESS, payload: res.data });
    } catch (error) {
      await handleRequestError(error, FETCH_ALL_VARIANTS_ERROR);
    }
  };

  const fetchPreviewSubscription = async (data: any) => {
    dispatch({ type: LOADING });
    try {
      const res = await httpClient().post('/customers/subscription/preview', data);
      dispatch({ type: FETCH_PREVIEW_SUBSCRIPTION_SUCCESS, payload: res.data });
    } catch (error) {
      await handleRequestError(error, FETCH_PREVIEW_SUBSCRIPTION_ERROR);
    }
  };

  const clearSubscriptionPreview = () => {
    dispatch({ type: CLEAR_SUBSCRIPTION_PREVIEW });
  };

  const handleRequestError = async (error: any, type: string) => {
    console.log(error);

    if (error instanceof AxiosError) {
      if (error.response?.status === 401) {
        localStorage.removeItem('nano-admin-user-auth-token');
        window.location.href = '/';
      }
    }

    await dispatch({ type, payload: error });
  };

  return (
    <SubscriptionContext.Provider
      value={{
        isLoading: state.isLoading,
        isError: state.isError,
        errorDetail: state.errorDetail,
        subscription: state.subscription,
        variants: state.variants,
        subscriptionPreview: state.subscriptionPreview,
        fetchPreviewSubscription,
        fetchAllVariants,
        setSubscription,
        updateSubscription,
        clearSubscriptionPreview,
      }}
    >
      {props.children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionState;
