export enum WizardStepIdentifiers {
  EMAIL = 'EMAIL',
  NAME = 'NAME',
  FILTERS_PROFILE = 'FILTERS_PROFILE',
  FILTERS_PROFILE_WITH_DROPDOWN = 'FILTERS_PROFILE_WITH_DROPDOWN',
  MULTI_SELECT = 'MULTI_SELECT',
  PROFILE_SUMMARY = 'PROFILE_SUMMARY',
  STOCK_SELECTION = 'STOCK_SELECTION',
  ORDER = 'ORDER',
  HOME_TYPE = 'HOME_TYPE',
  SQUARE_FOOTAGE = 'SQUARE_FOOTAGE',
  ACSYSTEM_AGE = 'ACSYSTEM_AGE',
  CONCERNS = 'CONCERNS',
  SHIPPING = 'SHIPPING',
}

export interface WizardStep {
  id?: string;
  position: number;
  required: boolean;
  type?: WizardStepIdentifiers;
}

export interface Wizard {
  id?: string;
  name: string;
  steps: Array<WizardStep>;
  enabled: boolean;
  default: boolean;
  createdAt?: string;
  updatedAt?: string;
}
