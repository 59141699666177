import {
  DELETE_WIZARDS_ERROR,
  DELETE_WIZARDS_SUCCESS,
  GET_WIZARDS_ERROR,
  GET_WIZARDS_SUCCESS,
  GET_WIZARD_ERROR,
  GET_WIZARD_SUCCESS,
  LOADING,
  PUT_WIZARDS_ERROR,
  PUT_WIZARDS_SUCCESS,
  POST_WIZARDS_ERROR,
  POST_WIZARDS_SUCCESS,
} from '../../types';

const WizardReducer = (state: any, action: any) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_WIZARDS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wizards: action.payload,
        isError: false,
      };
    case GET_WIZARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedWizard: action.payload,
        isError: false,
      };
    case PUT_WIZARDS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wizards: state.wizards.map((wizard: any) => (wizard.id === action.payload.id ? action.payload : wizard)),
        isError: false,
      };
    case POST_WIZARDS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wizards: [...state.wizards, action.payload],
        isError: false,
      };
    case DELETE_WIZARDS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wizards: state.wizards.filter((wizard: any) => wizard.id !== action.payload),
        isError: false,
      };

    case GET_WIZARDS_ERROR:
    case PUT_WIZARDS_ERROR:
    case DELETE_WIZARDS_ERROR:
    case GET_WIZARD_ERROR:
    case POST_WIZARDS_ERROR:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
};

export default WizardReducer;
