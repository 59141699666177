import { useState } from 'react';
import { DataGrid, GridActionsCellItem, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { Wizard } from '../../../interfaces/Wizard.interface';
import { APP_DOMAIN } from '../../../config';

const WizardsTable = ({ wizards, loading, handleEditWizard }: WizardsTableProps) => {
  const [pageSize, setPageSize] = useState(10);

  const columns = [
    { field: 'id', headerName: 'Wizard Id', width: 300 },
    { field: 'name', headerName: 'Name', width: 200 },
    {
      field: 'link',
      headerName: 'URL',
      width: 100,
      renderCell: (props: GridRenderCellParams<string>) => (
        <a href={`${APP_DOMAIN}?wizard_id=${props.id}`} target="_blank" rel="noreferrer">
          link
        </a>
      ),
    },
    { field: 'steps', headerName: 'Steps', width: 100, valueGetter: (params: any) => params.row.steps.length },
    {
      field: 'enabled',
      headerName: 'Enabled?',
      width: 100,
      type: 'boolean',
    },
    {
      field: 'default',
      headerName: 'Default?',
      width: 100,
      type: 'boolean',
    },
    {
      field: 'createdAt',
      headerName: 'Created at',
      width: 200,
      type: 'dateTime',
    },
    {
      field: 'updatedAt',
      headerName: 'Updated at',
      width: 200,
      type: 'dateTime',
    },
    {
      field: 'actions',
      type: 'actions',
      width: 75,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          onClick={() => handleEditWizard(params.row.id)}
          label="Edit wizard"
          title="Edit wizard"
        />,
      ],
    },
  ];

  return (
    <div style={{ width: '98%', margin: '0 auto' }}>
      <DataGrid
        rows={wizards}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        loading={loading}
        isRowSelectable={() => false}
        autoHeight
      />
    </div>
  );
};

type WizardsTableProps = {
  wizards: Array<Wizard>;
  loading: boolean;
  handleEditWizard: (wizardId: string) => void;
};

export default WizardsTable;
