import { useState } from 'react';
import { Link } from 'react-router-dom';
import { passwordReset } from '../../../services/firebase.service';

const PasswordResetForm = () => {
  // STATE
  const [email, setEmail] = useState('');

  const handleEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    passwordReset(email);
    alert(`Reset password email has been setn to ${email}. Check your inbox.`);
  };

  return (
    <div>
      <div className="loginLayout">
        <div className="orangeCol">
          <img src="https://nano-home.github.io/nano/assets/brand/NANO-Dashboard-Home-Icon.svg" alt="Nano Icon" />
        </div>
        <div className="formCol">
          <div className="adminLogoContainer">
            <img src="https://nano-home.github.io/nano/assets/brand/logotype-E9AA5F.svg" alt="NANO Logo" />
            <b>
              | Admin <span>Portal</span>
            </b>
          </div>
          <form onSubmit={handleSubmit}>
            <>
              <div className="loginInputsContainer">
                <i className="mdi mdi-account-circle"></i>
                <input
                  onChange={handleEmail}
                  value={email}
                  name="email"
                  type="email"
                  placeholder="Email"
                  autoComplete="off"
                  className="loginEmailInput"
                  required
                />
              </div>
              <div>
                <Link to="/login" className="forgotPasswordAnchor">
                  Back to Login
                </Link>
              </div>
              <div>
                <button type="submit" className="loginBtn" title="Reset password" disabled={email.trim().length === 0}>
                  Reset password
                </button>
              </div>
            </>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetForm;
