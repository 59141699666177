import { useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import { Wizard, WizardStep, WizardStepIdentifiers } from '../../../interfaces/Wizard.interface';
import { WizardContext } from '../../../store/context/wizard/WizardState';
import WizardStepRow from './WizardStepRow';
import { Checkbox, FormControl, FormControlLabel, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { includes } from 'lodash';

export type WizardModalProps = {
  selectedWizard: Wizard | null | undefined;
  open: boolean;
  handleClose: () => void;
  handleSubmit: (wizard: Wizard, id?: string) => void;
};

const WizardModal = ({ open, selectedWizard, handleSubmit, handleClose }: WizardModalProps) => {
  const dialogTitle = selectedWizard?.id ? `Update Wizard - ${selectedWizard.name}` : `Create Wizard`;

  const [name, setName] = useState<string>('');
  const [enabled, setEnabled] = useState<boolean>(false);
  const [defaultWizard, setDefaultWizard] = useState<boolean>(false);
  const [steps, setSteps] = useState<Array<WizardStep>>([]);
  const [stepOptions, setStepOptions] = useState<Array<WizardStepIdentifiers>>([]);

  const { isLoading } = useContext(WizardContext);

  useEffect(() => {
    setOriginalWizard();
    // eslint-disable-next-line
  }, [selectedWizard]);

  const setOriginalWizard = () => {
    setName(selectedWizard?.name || '');
    setEnabled(!!selectedWizard?.enabled);
    setDefaultWizard(!!selectedWizard?.default);
    setSteps(selectedWizard?.steps || []);
    const currentSteps = selectedWizard?.steps.map((step) => step.type);
    setStepOptions(Object.values(WizardStepIdentifiers).filter((stepType) => !includes(currentSteps, stepType)));
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnabled(event.target.checked);
  };

  const handleDefaultWizardChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDefaultWizard(event.target.checked);
    if (event.target.checked) setEnabled(true);
  };

  const buildWizardData = () => ({
    name,
    enabled,
    default: defaultWizard,
    steps,
  });

  const handleAddStep = () => {
    const newSteps = steps.concat([{ required: false, position: steps.length + 1 }]);
    setSteps(newSteps);
  };

  const handleRemoveStep = (position: number) => {
    let newSteps = [...steps];
    newSteps.splice(position - 1, 1);
    newSteps = newSteps.map((step) => {
      if (step.position > position) step.position--;
      return step;
    });

    setSteps(newSteps);
    const newStepTypes = newSteps.map((step) => step.type);
    setStepOptions(Object.values(WizardStepIdentifiers).filter((stepType) => !includes(newStepTypes, stepType)));
  };

  const handleTypeChange = (value: any, position: number) => {
    const editStep = Object.assign({}, steps[position - 1]);
    editStep.type = value;
    const newSteps = steps.map((step, index) => {
      if (index === position - 1) return editStep;
      return step;
    });
    setSteps(newSteps);
    const newStepTypes = newSteps.map((step) => step.type);
    setStepOptions(Object.values(WizardStepIdentifiers).filter((stepType) => !includes(newStepTypes, stepType)));
  };

  const handleRequiredChange = (value: any, position: number) => {
    const editStep = Object.assign({}, steps[position - 1]);
    editStep.required = value;
    const newSteps = steps.map((step, index) => {
      if (index === position - 1) return editStep;
      return step;
    });
    setSteps(newSteps);
  };

  const handleUpdate = async () => {
    if (!selectedWizard) return;
    const data = buildWizardData();
    if (!data) return;

    await handleSubmit(data, selectedWizard.id);
  };

  if (!selectedWizard) return null;

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <FormControl sx={{ m: 1, minWidth: 240 }}>
            <TextField
              autoFocus
              margin="dense"
              id="wizard-name"
              label="Wizard name"
              type="text"
              fullWidth
              variant="standard"
              value={name}
              onChange={handleNameChange}
            />
          </FormControl>
          {steps.map((step, index) => {
            return (
              <WizardStepRow
                key={`wizard-step-${index + 1}`}
                position={index + 1}
                step={step}
                handleRemoveStep={handleRemoveStep}
                handleTypeChange={handleTypeChange}
                handleRequiredChange={handleRequiredChange}
                stepOptions={stepOptions}
              />
            );
          })}
          <FormControl sx={{ marginTop: 0, marginBottom: 2, width: 200 }}>
            <div style={{ width: 'fit-content' }}>
              {steps.length < Object.keys(WizardStepIdentifiers).length && (
                <IconButton aria-label="add-filter" title="Add new step" onClick={handleAddStep}>
                  <AddCircleIcon />
                </IconButton>
              )}
            </div>
          </FormControl>
          <FormControl sx={{ m: 1, width: 455 }}>
            <FormControlLabel
              control={<Checkbox checked={defaultWizard} onChange={handleDefaultWizardChange} />}
              label="Set as Default"
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: 455 }}>
            <FormControlLabel
              control={<Checkbox checked={enabled} onChange={handleEnabledChange} />}
              label="Enabled"
              disabled={defaultWizard}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={setOriginalWizard}>Reset</Button>
          <LoadingButton onClick={handleUpdate} loading={isLoading}>
            {selectedWizard.id ? 'Update' : 'Create'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WizardModal;
