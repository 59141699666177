import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import every from 'lodash/every';

import { Customer } from '../../../interfaces/Customers.interface';
import { useContext, useEffect, useState } from 'react';
import { SUBSCRIPTION_INTERVALS, SUBSCRIPTION_INTERVALS_WITH_DISCOUNT } from '../../../utils';
import { SubscriptionContext } from '../../../store/context/subscription/SubscriptionState';
import CustomerFilterRow from './CustomerFilterRow';

const CustomerSubscriptionModal = ({
  open,
  selectedCustomer,
  handleSubmit,
  handleClose,
  variants,
}: CustomerSubscriptionModalProps) => {
  const {
    fetchPreviewSubscription,
    clearSubscriptionPreview,
    updateSubscription,
    subscriptionPreview,
    isLoading,
    isError,
    errorDetail,
  } = useContext(SubscriptionContext);

  const [selectedFilters, setSelectedFilters] = useState<Array<SelectedFilter>>([]);
  const [interval, setInterval] = useState<number | undefined>();
  const [deliveryDiscount, setDeliveryDiscount] = useState(false);
  const [premiumUpgrade, setPremiumUpgrade] = useState(false);
  const [premiumUpgradeAvailable, setPremiumUpgradeAvailable] = useState(false);
  const [intervalOptions, setIntervalOptions] = useState(SUBSCRIPTION_INTERVALS);

  useEffect(() => {
    setOriginalSubscription();
    // eslint-disable-next-line
  }, [selectedCustomer, variants]);

  const handleIntervalChange = (event: SelectChangeEvent) => {
    setInterval(parseInt(event.target.value));
  };

  const handleDeliveryDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateDeliveryDiscount(event.target.checked);
  };

  const updateDeliveryDiscount = (deliveryDiscount: boolean) => {
    setDeliveryDiscount(deliveryDiscount);
    setIntervalOptions(deliveryDiscount ? SUBSCRIPTION_INTERVALS_WITH_DISCOUNT : SUBSCRIPTION_INTERVALS);
  };

  const handlePremiumUpgradeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPremiumUpgrade(event.target.checked);
  };

  const handleAddFilter = (event: any) => {
    event.preventDefault();
    const filterList = selectedFilters.concat([{ variantId: '0', name: '' }]);
    setSelectedFilters(filterList);

    if (filterList.length > 1) {
      updateDeliveryDiscount(false);
    }
  };

  const handleRemoveFilter = (position: number) => {
    const newSelectedFilters = Object.assign([], selectedFilters);
    newSelectedFilters.splice(position - 1, 1);

    setSelectedFilters([...newSelectedFilters]);
    if (newSelectedFilters.length === 1) {
      updateDeliveryDiscount(true);
    }
  };

  const handleSubscriptionPreview = (event: any) => {
    event.preventDefault();
    if (!selectedCustomer || !variants) return;
    const data = buildSubscriptionData();
    if (!data) return;

    fetchPreviewSubscription(data);
  };

  const handleFilterVariantChange = (variantId: string, position: number) => {
    const newSelectedFilters = [...selectedFilters];
    newSelectedFilters[position - 1].variantId = variantId;

    setSelectedFilters(newSelectedFilters);
    const premiumAvailable =
      !!variants &&
      every(newSelectedFilters, (filter) => {
        return variants.find(
          (v) => v.id === parseInt(filter.variantId) || v['premium_id'] === parseInt(filter.variantId),
        )['premium_available'];
      });
    setPremiumUpgradeAvailable(premiumAvailable);
    if (!premiumAvailable) {
      setPremiumUpgrade(false);
      setIntervalOptions(SUBSCRIPTION_INTERVALS);
    }
  };

  const handleFilterNameChange = (name: string, position: number) => {
    const newSelectedFilters = [...selectedFilters];
    newSelectedFilters[position - 1].name = name;

    setSelectedFilters(newSelectedFilters);
  };

  const setOriginalSubscription = () => {
    if (selectedCustomer && variants) {
      const filters = selectedCustomer.filters?.map((filter) => ({
        variantId: filter.filterId,
        name: filter.label,
      }));
      setSelectedFilters(filters ?? []);
      setInterval(selectedCustomer.lastSubscription?.interval);
      setDeliveryDiscount(selectedCustomer.lastSubscription?.deliveryDiscount);
      setPremiumUpgrade(!!selectedCustomer.lastSubscription?.premiumUpgrade);
      setPremiumUpgradeAvailable(
        every(filters, (filter) => {
          const variantData = variants.find(
            (v) => v.id === parseInt(filter.variantId) || v['premium_id'] === parseInt(filter.variantId),
          );
          return variantData && variantData['premium_available'];
        }),
      );
      if (selectedCustomer.lastSubscription?.deliveryDiscount) setIntervalOptions(SUBSCRIPTION_INTERVALS_WITH_DISCOUNT);
    }
  };

  const handleReset = () => {
    setOriginalSubscription();
  };

  const buildSubscriptionData = () => {
    if (!selectedCustomer || !variants) return;

    const data = {
      customerId: selectedCustomer.id,
      updateSubscriptionData: {
        subscriptionInterval: interval,
        premiumUpgrade,
        ecoDelivery: deliveryDiscount,
        filters: selectedFilters.map((filter) => {
          const variant = variants.find(
            (v) => v.id === parseInt(filter.variantId) || v['premium_id'] === parseInt(filter.variantId),
          );
          const variantId = premiumUpgrade ? variant['premium_id'] : variant.id;

          return {
            id: variantId,
            label: filter.name,
          };
        }),
      },
    };

    return data;
  };

  const handleUpdate = async () => {
    if (!selectedCustomer || !variants) return;
    const data = buildSubscriptionData();
    if (!data) return;

    const updateResult = await updateSubscription(data);

    if (updateResult.success && !isError) {
      handleSubmit();
    }
  };

  const handleModalClose = () => {
    clearSubscriptionPreview();
    handleClose();
  };

  if (!selectedCustomer || !variants) return null;

  return (
    <div>
      <Dialog open={open} onClose={handleModalClose}>
        <DialogTitle>Update subscription for {selectedCustomer.user.name}</DialogTitle>
        <DialogContent>
          {selectedFilters.map((filter, index) => {
            const variantId =
              filter.variantId && filter.variantId !== '0'
                ? variants.find(
                    (v) => v.id === parseInt(filter.variantId) || v['premium_id'] === parseInt(filter.variantId),
                  ).id
                : parseInt(filter.variantId);

            return (
              <CustomerFilterRow
                key={`${index + 1}`}
                variants={variants}
                position={index + 1}
                selectedVariantId={variantId}
                filterName={filter.name}
                handleRemoveFilter={handleRemoveFilter}
                handleFilterVariantChange={handleFilterVariantChange}
                handleFilterNameChange={handleFilterNameChange}
              />
            );
          })}
          <FormControl sx={{ marginTop: 0, marginBottom: 2, width: 200 }}>
            <div style={{ width: 'fit-content' }}>
              {selectedFilters.length < 6 && (
                <IconButton aria-label="add-filter" title="Add new filter" onClick={handleAddFilter}>
                  <AddCircleIcon />
                </IconButton>
              )}
            </div>
          </FormControl>
          <FormControl sx={{ m: 1, width: 455 }}>
            <InputLabel id="interval-select-label">Subscription Interval</InputLabel>
            {/* {interval && ( */}
            <Select
              labelId="interval-select-label"
              id="interval-select"
              value={interval ? interval.toString() : ''}
              label="Subscription Interval"
              onChange={handleIntervalChange}
            >
              {intervalOptions.map((subInterval) => (
                <MenuItem key={subInterval.value} value={subInterval.value}>
                  {subInterval.name}
                </MenuItem>
              ))}
            </Select>
            {/* )} */}
          </FormControl>
          <FormControl sx={{ m: 1, width: 455 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={deliveryDiscount}
                  onChange={handleDeliveryDiscountChange}
                  disabled={selectedFilters.length !== 1}
                />
              }
              label="Delivery Discount"
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: 455 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={premiumUpgrade}
                  onChange={handlePremiumUpgradeChange}
                  disabled={!premiumUpgradeAvailable}
                />
              }
              label="Premium Upgrade"
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: 455 }}>
            <Stack direction="row" spacing={2}>
              <LoadingButton
                loading={isLoading}
                variant="outlined"
                onClick={handleSubscriptionPreview}
                disabled={!interval}
              >
                Preview changes
              </LoadingButton>
            </Stack>
          </FormControl>
          <FormControl sx={{ m: 1, width: 455 }}>
            <Stack direction="column" spacing={1}>
              <Typography variant="h6" component="div">
                New Description
              </Typography>
              <Typography variant="body1">{subscriptionPreview.newDescription || '-'}</Typography>
              <Typography variant="h6" component="div">
                New Total
              </Typography>
              <Typography variant="body1">{`$ ${subscriptionPreview.newTotal || '-'}`}</Typography>
            </Stack>
          </FormControl>
          {isError && errorDetail && (
            <Typography variant="body1" color="red">
              {errorDetail}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Cancel</Button>
          <Button onClick={handleReset}>Reset</Button>
          <LoadingButton onClick={handleUpdate} loading={isLoading} disabled={!interval}>
            Update
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomerSubscriptionModal;
export type CustomerSubscriptionModalProps = {
  selectedCustomer: Customer | null | undefined;
  open: boolean;
  variants: Array<any> | null;
  handleClose: () => void;
  handleSubmit: () => void;
};

type SelectedFilter = {
  variantId: string;
  name: string;
};
