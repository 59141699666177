import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import LoadingButton from '@mui/lab/LoadingButton';

import { Customer } from '../../../interfaces/Customers.interface';
import { useContext, useEffect, useState } from 'react';
import { SubscriptionContext } from '../../../store/context/subscription/SubscriptionState';
import httpClient from '../../../services/httpClient.service';

const modalContent = {
  cancel: {
    title: 'Cancelling subscription for',
    text: 'Are you sure you want to cancel the subscription for this customer? No new orders will be created and scheduled payments will be cancelled immediately.',
    button: 'Confirm unsubscribe',
  },
  resume: {
    title: 'Reactivating subscription for',
    text: 'Are you sure you want to reactivate the subscription for this customer? By confirming the changes fresh subscription cycle will start inmediately and a new filters will be delivered',
    button: 'Confirm resume',
  },
};

const CustomerSubscriptionCancelOrResumeModal = ({
  open,
  selectedCustomer,
  handleSubmit,
  handleClose,
}: CustomerSubscriptionCancelModalProps) => {
  const { updateSubscription, isLoading } = useContext(SubscriptionContext);
  const [content, setContent] = useState(modalContent.cancel);
  const [isCancelling, setIsCancelling] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);

  useEffect(() => {
    const cancelling = !selectedCustomer?.lastSubscription?.cancelled;
    setIsCancelling(cancelling);
    setContent(cancelling ? modalContent.cancel : modalContent.resume);
  }, [selectedCustomer]);

  const handleConfirm = async () => {
    if (!selectedCustomer) return;

    if (isCancelling) {
      await updateSubscription({
        customerId: selectedCustomer.id,
        cancel: true,
      });
      handleSubmit();
    } else {
      setLocalLoading(true);
      await httpClient().get(`/customers/${selectedCustomer.id}/resume-subscription`);

      // Wait for customer.lastSubscription.subscribedAt to be set by Stripe webhook
      // consider updating to websockets communication or similar to avoid failures on this unreliable setTimeout
      setTimeout(() => {
        setLocalLoading(false);
        handleSubmit();
      }, 3000);
    }
  };

  const handleModalClose = () => {
    handleClose();
  };

  if (!selectedCustomer) return null;

  return (
    <div>
      <Dialog open={open} onClose={handleModalClose}>
        <DialogTitle>
          {content.title} {selectedCustomer.user.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{content.text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton onClick={handleConfirm} color="error" loading={isLoading || localLoading}>
            {content.button}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomerSubscriptionCancelOrResumeModal;
export type CustomerSubscriptionCancelModalProps = {
  selectedCustomer: Customer | null | undefined;
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
};
