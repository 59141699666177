import React from 'react';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import PrivateLayout from '../components/layout/PrivateLayout';
import { AuthContext } from '../store/context/auth/AuthState';

const PrivateRoute = ({ children }: PrivateRouteTypes) => {
  const { isAuthenticated } = useContext(AuthContext);

  return isAuthenticated ? <PrivateLayout>{children}</PrivateLayout> : <Navigate to="/login" />;
};
export default PrivateRoute;

type PrivateRouteTypes = {
  children: any;
};
