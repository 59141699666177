import {
  LOADING,
  SET_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_ERROR,
  UPDATE_SUBSCRIPTION_SUCCESS,
  FETCH_ALL_VARIANTS_ERROR,
  FETCH_ALL_VARIANTS_SUCCESS,
  FETCH_PREVIEW_SUBSCRIPTION_ERROR,
  FETCH_PREVIEW_SUBSCRIPTION_SUCCESS,
  CLEAR_SUBSCRIPTION_PREVIEW,
} from '../../types';

const SusbcriptionReducer = (state: any, action: any) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SET_SUBSCRIPTION:
      return {
        ...state,
        isLoading: false,
        subscription: action.payload,
      };
    case UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        subscription: action.payload,
        subscriptionPreview: {},
      };
    case FETCH_ALL_VARIANTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        variants: action.payload,
      };

    case FETCH_PREVIEW_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errorDetail: '',
        subscriptionPreview: action.payload,
      };

    case CLEAR_SUBSCRIPTION_PREVIEW:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errorDetail: '',
        subscriptionPreview: {},
      };

    case FETCH_PREVIEW_SUBSCRIPTION_ERROR:
    case FETCH_ALL_VARIANTS_ERROR:
    case UPDATE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorDetail: action.payload.response?.data?.message,
      };
    default:
      return state;
  }
};

export default SusbcriptionReducer;
