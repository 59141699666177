import { NavLink } from 'react-router-dom';

const Home = () => {
  const routes = [
    { name: 'Customers', path: '/customers' },
    { name: 'Wizards', path: '/wizards' },
  ];

  return (
    <>
      {routes.map(({ name, path }, index) => (
        <div>
          <NavLink key={index} to={path} className={name}>
            <i></i>
            {name}
          </NavLink>
        </div>
      ))}
    </>
  );
};

export default Home;
