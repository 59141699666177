import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CancelIcon from '@mui/icons-material/Cancel';

import { useEffect, useState } from 'react';
import { WizardStep, WizardStepIdentifiers } from '../../../interfaces/Wizard.interface';
import { Checkbox, FormControlLabel } from '@mui/material';

export type WizardStepProps = {
  step: WizardStep;
  position: number;
  stepOptions: Array<string>;
  handleRemoveStep: (position: number) => void;
  handleTypeChange: (value: any, position: number) => void;
  handleRequiredChange: (value: any, position: number) => void;
};

const WizardStepRow = ({
  step,
  position,
  stepOptions,
  handleRemoveStep,
  handleTypeChange,
  handleRequiredChange,
}: WizardStepProps) => {
  const [type, setType] = useState<WizardStepIdentifiers | undefined>(undefined);
  const [required, setRequired] = useState<boolean>(false);

  useEffect(() => {
    if (step) {
      setType(step.type);
      setRequired(step.required);
    }
  }, [step]);

  const handleType = (event: SelectChangeEvent) => {
    handleTypeChange(event.target.value, position);
  };

  const handleRequired = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleRequiredChange(event.target.checked, position);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 240 }}>
        <InputLabel id="step-type-label">Type</InputLabel>
        <Select
          labelId="step-type-label"
          id="step-type"
          value={type?.toString() || ''}
          label="Step type"
          onChange={handleType}
        >
          {stepOptions.concat([step.type?.toString() || '']).map((wizardType) => (
            <MenuItem key={wizardType} value={wizardType}>
              {wizardType.split('_').join(' ')}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, width: 100 }}>
        <FormControlLabel control={<Checkbox checked={required} onChange={handleRequired} />} label="Required" />
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 20 }}>
        <IconButton aria-label="add-filter" title="Add new filter" onClick={() => handleRemoveStep(position)}>
          <CancelIcon />
        </IconButton>
      </FormControl>
    </div>
  );
};

export default WizardStepRow;
