import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../store/context/auth/AuthState';
import Spinner from '../../common/spinner/Spinner';

const LoginForm = () => {
  // CONTEXT
  const { login, isLoading, isError, messageError } = useContext(AuthContext);

  // STATE
  const [formData, setFormData] = useState({ email: '', password: '' });

  const handleEmail = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePassword = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    login(formData.email, formData.password);
  };

  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="loginLayout">
          <div className="orangeCol">
            <img src="https://nano-home.github.io/nano/assets/brand/NANO-Dashboard-Home-Icon.svg" alt="Nano Icon" />
          </div>
          <div className="formCol">
            <div className="adminLogoContainer">
              <img src="https://nano-home.github.io/nano/assets/brand/logotype-E9AA5F.svg" alt="NANO Logo" />
              <b>
                | Admin <span>Portal</span>
              </b>
            </div>
            <form onSubmit={handleSubmit}>
              <>
                <div className="loginInputsContainer">
                  <i className="mdi mdi-account-circle"></i>
                  <input
                    onChange={handleEmail}
                    value={formData.email}
                    name="email"
                    type="email"
                    placeholder="Email"
                    autoComplete="off"
                    className="loginEmailInput"
                    required
                  />
                </div>
                <div className="input loginInputsContainer last">
                  <i className="mdi mdi-lock"></i>
                  <input
                    onChange={handlePassword}
                    value={formData.password}
                    name="password"
                    type="password"
                    placeholder="Password"
                    autoComplete="off"
                    className="loginPasswordInput"
                    required
                  />
                </div>
                {isError && (
                  <div className="errorMessage">
                    <span>
                      <i className="mdi mdi-alert"></i>
                      {messageError?.toString() || ''}
                    </span>
                  </div>
                )}
                <div>
                  <Link to="/password-reset" className="forgotPasswordAnchor">
                    Forgot your password?
                  </Link>
                </div>
                <div>
                  <button
                    type="submit"
                    className="loginBtn"
                    title="Login"
                    disabled={formData.email.trim().length === 0}
                  >
                    Login
                  </button>
                </div>
              </>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginForm;
