import {
  DELETE_CUSTOMERS_ERROR,
  DELETE_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_ERROR,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMER_ERROR,
  GET_CUSTOMER_SUCCESS,
  LOADING,
  PUT_CUSTOMERS_ERROR,
  PUT_CUSTOMERS_SUCCESS,
} from '../../types';

const CustomerReducer = (state: any, action: any) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        customers: action.payload,
        isError: false,
      };
    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedCustomer: action.payload,
        isError: false,
      };
    case PUT_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        customers: state.customers.map((customer: any) =>
          customer.user.email === action.payload.customer.user.email ? action.payload.customer : customer,
        ),
        isError: false,
      };
    case DELETE_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        customers: state.customers.filter((customer: any) => customer.user.email !== action.payload),
        isError: false,
      };

    case GET_CUSTOMERS_ERROR:
    case PUT_CUSTOMERS_ERROR:
    case DELETE_CUSTOMERS_ERROR:
    case GET_CUSTOMER_ERROR:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
};

export default CustomerReducer;
