import '../../Admin.scss';

const PublicLayout = ({ children }: any) => {
  return (
    <main id="main" role="main">
      {children}
    </main>
  );
};
export default PublicLayout;
