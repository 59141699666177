import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../store/context/auth/AuthState';

import Layout from '../components/layout/PublicLayout';
import Spinner from '../components/common/spinner/Spinner';
import PasswordResetForm from '../components/pages/login/PasswordResetForm';

const PasswordReset = () => {
  const navigate = useNavigate();

  // CONTEXT
  const { isAuthenticated, isLoading } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthenticated) navigate('/customers');

    // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <Layout>
      <div>
        <div>{isLoading ? <Spinner /> : <PasswordResetForm />}</div>
      </div>
    </Layout>
  );
};

export default PasswordReset;
