import { useContext, useEffect, useState } from 'react';
import { Customer } from '../../../interfaces/Customers.interface';
import { CustomerContext } from '../../../store/context/customer/CustomerState';
import CustomerProfileModal from './CustomerProfileModal';
import CustomerSubscriptionModal from './CustomerSubscriptionModal';
import CustomersTable from './CustomersTable';
import { SubscriptionContext } from '../../../store/context/subscription/SubscriptionState';
import CustomerSubscriptionCancelOrResumeModal from './CustomerSubscriptionCancelOrResumeModal';

const Customers = () => {
  // CONTEXT
  const { getCustomers, customers, isLoading } = useContext(CustomerContext);
  const { fetchAllVariants, variants } = useContext(SubscriptionContext);

  const [selectedCustomer, setSelectedCustomer] = useState<Customer | undefined | null>(null);
  const [editingProfile, setEditingProfile] = useState(false);
  const [editingSubscription, setEditingSubscription] = useState(false);
  const [showCancelOrResumeModal, setShowCancelOrResumeModal] = useState(false);

  const handleEditProfile = (customerId: string) => {
    const customer = customers.find((customer) => customer.id === customerId);
    setSelectedCustomer(customer);
    setEditingProfile(true);
  };

  const handleEditSubscription = (customerId: string) => {
    const customer = customers.find((customer) => customer.id === customerId);
    setSelectedCustomer(customer);
    setEditingSubscription(true);
  };

  const handleModalClose = () => {
    setEditingProfile(false);
    setEditingSubscription(false);
    setShowCancelOrResumeModal(false);
    setSelectedCustomer(null);
  };

  const handleUpdated = () => {
    setEditingProfile(false);
    setEditingSubscription(false);
    setShowCancelOrResumeModal(false);
    setSelectedCustomer(null);
    getCustomers();
  };

  const handleCancelOrReactivateSubscription = (customerId: string) => {
    const customer = customers.find((customer) => customer.id === customerId);
    setSelectedCustomer(customer);
    setShowCancelOrResumeModal(true);
  };

  useEffect(() => {
    getCustomers();
    fetchAllVariants();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CustomersTable
        customers={customers}
        loading={isLoading}
        handleEditProfile={handleEditProfile}
        handleEditSubscription={handleEditSubscription}
        handleCancelOrResumeSubscription={handleCancelOrReactivateSubscription}
      />
      <CustomerProfileModal
        selectedCustomer={selectedCustomer}
        open={editingProfile}
        handleClose={handleModalClose}
        handleSubmit={handleUpdated}
      />
      <CustomerSubscriptionModal
        selectedCustomer={selectedCustomer}
        open={editingSubscription}
        variants={variants}
        handleClose={handleModalClose}
        handleSubmit={handleUpdated}
      />
      <CustomerSubscriptionCancelOrResumeModal
        selectedCustomer={selectedCustomer}
        open={showCancelOrResumeModal}
        handleClose={handleModalClose}
        handleSubmit={handleUpdated}
      />
    </>
  );
};

export default Customers;
