import { createContext, useReducer } from 'react';
import { LOGIN, LOGOUT, AUTH_ERROR, AUTH_SUCCESS } from '../../types';
import AuthReducer from './AuthReducer';
import httpClient from '../../../services/httpClient.service';

import { refreshAuthToken, signInWithPassword, signOutUser } from '../../../services/firebase.service';

interface IAuthContext {
  isAuthenticated?: boolean;
  isLoading: boolean;
  isError: boolean;
  messageError: string;
  login: (email: string, password: string) => void;
  verify: () => void;
  logout: () => void;
}

const defaultState: IAuthContext = {
  isAuthenticated: localStorage.getItem('nano-admin-user-auth-token') ? true : false,
  isLoading: false,
  isError: false,
  messageError: '',
  login: () => {},
  verify: () => {},
  logout: () => {},
};

export const AuthContext = createContext<IAuthContext>(defaultState);

const AuthState = (props: any) => {
  const initialState = defaultState;

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const login = async (email: string, password: string) => {
    dispatch({ type: LOGIN });
    try {
      const firebaseUser: any = await signInWithPassword(email, password);
      const authToken = firebaseUser.user.accessToken;
      const resUser = await httpClient().post('/authentication/verify', { authToken });
      localStorage.setItem('nano-admin-user-auth-token', authToken);
      dispatch({ type: AUTH_SUCCESS, payload: resUser.data });
    } catch (error: any) {
      dispatch({ type: AUTH_ERROR, payload: error });
    }
  };
  const verify = async () => {
    dispatch({ type: LOGIN });
    try {
      const newToken: any = await refreshAuthToken();
      localStorage.setItem('nano-admin-user-auth-token', newToken);

      // const authToken = localStorage.getItem('nano-admin-user-auth-token');
      const res = await httpClient().post('/authentication/verify');
      if (!res.data.message) {
        dispatch({ type: AUTH_SUCCESS, payload: res.data });
      } else {
        localStorage.removeItem('nano-admin-user-auth-token');
        dispatch({ type: AUTH_ERROR, payload: res.data });
      }
    } catch (error) {
      localStorage.removeItem('nano-admin-user-auth-token');
      dispatch({ type: AUTH_ERROR });
    }
  };
  const logout = async () => {
    try {
      await signOutUser();
      localStorage.removeItem('nano-user-email');
      localStorage.removeItem('nano-admin-user-auth-token');
      dispatch({ type: LOGOUT });
    } catch (error) {}
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        isLoading: state.isLoading,
        isError: state.isError,
        messageError: state.messageError,
        login,
        verify,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
