import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from 'firebase/auth';
import { firebaseConfig } from '../config';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

/* Obtains and refresh auth token */
export const refreshAuthToken = () =>
  new Promise((resolve, reject) => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        let token = await user.getIdToken();
        resolve(token);
      } else {
        reject(new Error('No user logged in'));
      }
    });
  });

/* SignIn with email and password */
export const signInWithPassword = (email: string, password: string) =>
  new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });

/* Sign out */
export const signOutUser = () =>
  new Promise((resolve, reject) => {
    signOut(auth)
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  });

/* Send password reset email */
export const passwordReset = (email: string) =>
  new Promise((resolve, reject) => {
    sendPasswordResetEmail(auth, email)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
