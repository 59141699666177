import { useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { Customer } from '../../../interfaces/Customers.interface';
import { CustomerContext } from '../../../store/context/customer/CustomerState';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import { STATES } from '../../../utils';

export type CustomerProfileModalProps = {
  selectedCustomer: Customer | null | undefined;
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
};

const homeTypes = [
  {
    value: 'Single-Family House',
    label: 'Single-Family House',
  },
  {
    value: 'Duplex',
    label: 'Duplex',
  },
  {
    value: 'Condo / Apartment',
    label: 'Condo / Apartment',
  },
];

const CustomerProfileModal = ({ open, selectedCustomer, handleSubmit, handleClose }: CustomerProfileModalProps) => {
  const [name, setName] = useState<string | undefined>('');
  const [email, setEmail] = useState<string | undefined>('');
  const [phone, setPhone] = useState<string | undefined>('');
  const [homeType, setHomeType] = useState<string | undefined>('');
  const [squareFootage, setSquareFootage] = useState<string | number | undefined>('');
  const [mervRating, setMervRating] = useState<string | number | undefined>('');
  const [addressLine2, setAddressLine2] = useState<string | undefined>('');
  const [addressCity, setAddressCity] = useState<string | undefined>('');
  const [addressState, setAddressState] = useState<string | undefined>('');
  const [addressZipCode, setAddressZipCode] = useState<string | number | undefined>('');

  const { updateCustomer, isLoading } = useContext(CustomerContext);

  const {
    ready,
    value,
    suggestions: { data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  useEffect(() => {
    setName(selectedCustomer?.user.name || '');
    setEmail(selectedCustomer?.user.email || '');
    setPhone(selectedCustomer?.phone || '');
    setHomeType(selectedCustomer?.homeType);
    setSquareFootage(selectedCustomer?.homeSquareFootage);
    setMervRating(selectedCustomer?.mervRating);
    setValue(selectedCustomer?.activeAddress?.addressLine1 || '');
    setAddressLine2(selectedCustomer?.activeAddress?.addressLine2 || '');
    setAddressCity(selectedCustomer?.activeAddress?.city || '');
    setAddressState(selectedCustomer?.activeAddress?.state || '');
    setAddressZipCode(selectedCustomer?.activeAddress?.zipCode || '');
  }, [selectedCustomer, setValue]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };

  const handleHomeTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHomeType(event.target.value);
  };

  const handleSquareFootageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSquareFootage(parseInt(event.target.value));
  };

  const handleMervRatingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMervRating(parseInt(event.target.value));
  };

  const buildCustomerData = () => {
    const data = {
      name,
      email,
      phone,
      home_type: homeType,
      home_square_footage: squareFootage,
      merv_rating: mervRating,
      address_line_1: value,
      address_line_2: addressLine2,
      city: addressCity,
      state: addressState,
      zip_code: addressZipCode,
    };
    return data;
  };

  const handleUpdate = async () => {
    if (!selectedCustomer) return;
    const data = buildCustomerData();
    if (!data) return;
    await updateCustomer(data?.email || '', data);

    handleSubmit();
  };

  const handleSelect = ({ description, structured_formatting: { main_text } }: any) => /*() => */ {
    setValue(main_text, false);
    getGeocode({ address: description })
      .then((result) => {
        let address1 = '';
        let postcode = '';

        for (const component of result[0].address_components) {
          const componentType = component.types[0];
          switch (componentType) {
            case 'street_number': {
              address1 = `${component.long_name} `;
              break;
            }

            case 'route': {
              address1 += component.short_name;
              break;
            }

            case 'postal_code': {
              postcode = `${component.long_name}${postcode}`;
              break;
            }

            case 'postal_code_suffix': {
              postcode = `${postcode}-${component.long_name}`;
              break;
            }
            case 'locality':
              setAddressCity(component.long_name);
              break;
            case 'administrative_area_level_1': {
              setAddressState(STATES.find((state) => state.postalCode === component.short_name)?.name || '');
              break;
            }
            case 'country': {
              break;
            }
            default: {
              console.log('no type found');
            }
          }
        }
        setValue(address1);
        setAddressZipCode(postcode.substring(0, 5));
      })
      .catch((error) => {
        console.log('😱 Error: ', error);
      });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Profile</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                type="text"
                fullWidth
                variant="outlined"
                value={name}
                onChange={handleNameChange}
              />
              <TextField
                autoFocus
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                fullWidth
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
              />
              <TextField
                autoFocus
                margin="dense"
                id="phone"
                label="Phone"
                type="phone"
                fullWidth
                variant="outlined"
                value={phone || ''}
                onChange={handlePhoneChange}
              />
              <TextField
                id="outlined-select-currency"
                select
                fullWidth
                label="Home Type"
                value={homeType || ''}
                onChange={handleHomeTypeChange}
              >
                {homeTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                autoFocus
                margin="dense"
                fullWidth
                id="squareFootage"
                label="Square Footage"
                type="number"
                variant="outlined"
                value={squareFootage || ''}
                onChange={handleSquareFootageChange}
              />
              <TextField
                autoFocus
                margin="dense"
                id="mervRating"
                label="MERV Rating"
                type="number"
                fullWidth
                variant="outlined"
                value={mervRating || ''}
                onChange={handleMervRatingChange}
              />
              <div className="shippingAddressStep">
                <div className="shippingAddressFormGroup">
                  <label htmlFor="" className="addressLines addressLineOne">
                    <Autocomplete
                      id="addressLine1"
                      fullWidth
                      value={value || ''}
                      onInputChange={(event, newInputValue) => {
                        setValue(newInputValue);
                      }}
                      onChange={(event: any, newValue: any) => {
                        handleSelect(newValue);
                      }}
                      options={data || []}
                      getOptionLabel={(option) => {
                        if (option) {
                          return typeof option === 'string' ? option : option.structured_formatting.main_text;
                        }
                        return '';
                      }}
                      noOptionsText="No locations"
                      filterOptions={(x) => x}
                      renderInput={(params) => <TextField {...params} variant="outlined" label="ADDRESS LINE 1" />}
                      disabled={!ready}
                      renderOption={(props: any, option: any) => {
                        return (
                          <MenuItem {...props} key={option.place_id}>
                            <strong>{option.structured_formatting.main_text}</strong>&nbsp;
                            <small>{option.structured_formatting.secondary_text}</small>
                          </MenuItem>
                        );
                      }}
                    />
                  </label>

                  <TextField
                    margin="dense"
                    fullWidth
                    id="addressLine2"
                    label="ADDRESS LINE 2"
                    type="text"
                    variant="outlined"
                    value={addressLine2}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => setAddressLine2(e.target.value)}
                  />

                  <TextField
                    margin="dense"
                    fullWidth
                    id="addressCity"
                    label="City"
                    type="text"
                    variant="outlined"
                    value={addressCity}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => setAddressCity(e.target.value)}
                  />

                  <TextField
                    margin="dense"
                    fullWidth
                    variant="outlined"
                    value={addressState}
                    label="State"
                    onChange={(e) => setAddressState(e.target.value)}
                    select
                  >
                    <MenuItem disabled value="">
                      Select State
                    </MenuItem>
                    {STATES.map((state, index) => (
                      <MenuItem key={index} value={state.name}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <label htmlFor="" className="addressLines">
                    <TextField
                      autoComplete="off"
                      margin="dense"
                      fullWidth
                      id="zipCode"
                      label="Zip Code"
                      variant="outlined"
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 5 }}
                      value={addressZipCode}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => setAddressZipCode(e.target.value)}
                    />
                  </label>
                </div>
              </div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton onClick={handleUpdate} loading={isLoading}>
            Update
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomerProfileModal;
