import { AxiosError } from 'axios';
import { createContext, useReducer } from 'react';
import { Customer } from '../../../interfaces/Customers.interface';
import httpClient from '../../../services/httpClient.service';
import {
  DELETE_CUSTOMERS_ERROR,
  DELETE_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_ERROR,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMER_ERROR,
  GET_CUSTOMER_SUCCESS,
  LOADING,
  PUT_CUSTOMERS_ERROR,
  PUT_CUSTOMERS_SUCCESS,
} from '../../types';
import CustomerReducer from './CustomerReducer';

// CONTEXT
interface ICustomerContext {
  isLoading: boolean;
  isError: boolean;
  customers: Customer[];
  selectedCustomer: Customer | null;
  getCustomers(query?: string): void;
  getCustomer(id: string): void;
  updateCustomer(email: string, newData: object): void;
  deleteCustomer(email: string): void;
}

const defaultState: ICustomerContext = {
  isLoading: false,
  isError: false,
  customers: [],
  selectedCustomer: null,
  getCustomers: () => {},
  getCustomer: () => {},
  updateCustomer: () => {},
  deleteCustomer: () => {},
};

export const CustomerContext = createContext<ICustomerContext>(defaultState);

// STATE
const CustomerState = (props: any) => {
  const initialState = defaultState;

  const [state, dispatch] = useReducer(CustomerReducer, initialState);

  const getCustomers = async (query = '') => {
    dispatch({ type: LOADING });
    try {
      const res = await httpClient().get(`/customers/all${query}`);
      dispatch({ type: GET_CUSTOMERS_SUCCESS, payload: res.data });
    } catch (error) {
      await handleRequestError(error, GET_CUSTOMERS_ERROR);
    }
  };

  const getCustomer = async (id: string) => {
    dispatch({ type: LOADING });
    try {
      const res = await httpClient().get(`/customers/${id}`);
      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: res.data });
    } catch (error) {
      await handleRequestError(error, GET_CUSTOMER_ERROR);
    }
  };

  const updateCustomer = async (email: string, newData: object) => {
    dispatch({ type: LOADING });
    try {
      const res = await httpClient().put(`/users/${email}`, newData);
      dispatch({ type: PUT_CUSTOMERS_SUCCESS, payload: res.data });
    } catch (error) {
      await handleRequestError(error, PUT_CUSTOMERS_ERROR);
    }
  };

  const deleteCustomer = async (email: string) => {
    dispatch({ type: LOADING });
    try {
      await httpClient().delete(`/users/${email}`);
      dispatch({ type: DELETE_CUSTOMERS_SUCCESS, payload: email });
    } catch (error) {
      await handleRequestError(error, DELETE_CUSTOMERS_ERROR);
    }
  };

  const handleRequestError = (error: any, type: string) => {
    console.log(error);

    if (error instanceof AxiosError) {
      if (error.response?.status === 401) {
        localStorage.removeItem('nano-admin-user-auth-token');
        window.location.href = '/';
      }
    }

    dispatch({ type, payload: error });
  };

  return (
    <CustomerContext.Provider
      value={{
        isLoading: state.isLoading,
        isError: state.isError,
        customers: state.customers,
        selectedCustomer: state.selectedCustomer,
        getCustomer,
        getCustomers,
        updateCustomer,
        deleteCustomer,
      }}
    >
      {props.children}
    </CustomerContext.Provider>
  );
};

export default CustomerState;
