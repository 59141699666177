const NotFound = ({ errorType }: { errorType: number }) => {
  const ERRORS: any = {
    404: {
      message: "The page you're looking for isn't out there.",
      icon: 'https://nano-home.github.io/nano/assets/brand/404-Not-Found-Icon.svg',
      alt: 'Page Not Found Icon',
    },
    500: {
      message: 'Something went wrong. Try again later.',
      icon: 'https://nano-home.github.io/nano/assets/brand/500-Internal-Server-Error-Icon.svg',
      alt: 'Internal Server Error Icon',
    },
  };

  const errorData = ERRORS[errorType];

  return (
    <>
      <header
        id="section-header"
        className="Header Header--sidebar Header--initialized Header--withIcons"
        role="banner"
      >
        <div className="Header__Wrapper">
          <div className="Header__FlexItem Header__FlexItem--logo">
            <div className="Header__Logo">
              <a href="http://nanohome.com/" className="Header__LogoLink">
                <img
                  src="//cdn.shopify.com/s/files/1/0611/2120/0349/t/47/assets/logotype-F5A64F.svg?v=3049963328439442714"
                  alt="NANO Logo"
                />
              </a>
            </div>
          </div>

          <div className="headerCTA">
            <a href="/login" className="signInBtn">
              Sign In
            </a>
          </div>
        </div>
      </header>

      <div className="NANO_PageNotFound">
        <div className="NANO_PageNotFoundFloating">
          <div className="NANO_CenteredContent">
            <img src={errorData.icon} alt={errorData.alt} />
            <h1>{errorData.message}</h1>
            <a href="/">Go Back</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
