import { useState } from 'react';
import { DataGrid, GridActionsCellItem, GridRowParams } from '@mui/x-data-grid';
import PersonPin from '@mui/icons-material/PersonPin';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import SyncIcon from '@mui/icons-material/Sync';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import { Customer } from '../../../interfaces/Customers.interface';
import { calculateDeliverySchedule } from '../../../utils';
import { RemoveShoppingCart } from '@mui/icons-material';

const CustomersTable = ({
  customers,
  loading,
  handleEditProfile,
  handleEditSubscription,
  handleCancelOrResumeSubscription,
}: CustomersTableProps) => {
  const [pageSize, setPageSize] = useState(10);

  const columns = [
    { field: 'id', headerName: 'Customer Id', width: 300 },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      valueGetter: (params: any) => params.row.user.email,
    },
    { field: 'name', headerName: 'Name', width: 200, valueGetter: (params: any) => params.row.user.name },
    {
      field: 'subscribed',
      headerName: 'Subscribed?',
      width: 100,
      type: 'boolean',
      valueGetter: (params: any) =>
        !!params.row.lastSubscription?.subscribedAt && !params.row.lastSubscription.cancelled,
    },
    {
      field: 'subscribed_at',
      headerName: 'Subscribed at',
      width: 200,
      type: 'dateTime',
      valueGetter: (params: any) => (params.row.lastSubscription ? params.row.lastSubscription.subscribedAt : 'N/A'),
    },
    {
      field: 'selected_filter_ids',
      headerName: '# Filters',
      width: 130,
      valueGetter: (params: any) => params.row.filters.length,
    },
    {
      field: 'premium_upgrade',
      headerName: 'Carbon?',
      width: 100,
      type: 'boolean',
      valueGetter: (params: any) => params.row.lastSubscription?.premiumUpgrade,
    },
    {
      field: 'subscription_interval',
      headerName: 'Interval',
      width: 130,
      type: 'number',
      valueGetter: (params: any) => params.row.lastSubscription?.interval,
    },
    {
      field: 'delivery_discount',
      headerName: 'Delivery Discount?',
      width: 160,
      type: 'boolean',
      valueGetter: (params: any) => params.row.lastSubscription?.deliveryDiscount,
    },
    {
      field: 'schedule',
      headerName: 'Delivered every',
      width: 130,
      valueGetter: (params: any) =>
        calculateDeliverySchedule(params.row.lastSubscription?.deliveryDiscount, params.row.lastSubscription?.interval),
    },
    {
      field: 'subscription_months_paid',
      headerName: '# Payments',
      width: 100,
      type: 'number',
      valueGetter: (params: any) => (params.row.lastSubscription ? params.row.lastSubscription.monthsPaid : 'N/A'),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 200,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<PersonPin />}
          onClick={() => handleEditProfile(params.row.id)}
          label="Edit profile info"
          title="Edit profile info"
        />,
        <GridActionsCellItem
          icon={<ShoppingCart />}
          onClick={() => handleEditSubscription(params.row.id)}
          label="Edit subscription"
          title="Edit subscription"
        />,
        <GridActionsCellItem
          icon={<RemoveShoppingCart />}
          onClick={() => handleCancelOrResumeSubscription(params.row.id)}
          label="Cancel subscription"
          title="Cancel subscription"
          disabled={
            !params.row.lastSubscription ||
            !params.row.lastSubscription.subscribedAt ||
            params.row.lastSubscription.cancelled
          }
        />,
        <GridActionsCellItem
          icon={params.row.lastSubscription?.cancelled ? <SyncIcon /> : <SyncDisabledIcon />}
          onClick={() => handleCancelOrResumeSubscription(params.row.id)}
          label="Resume subscription"
          title="Resume subscription"
          disabled={!params.row.lastSubscription?.cancelled}
        />,
      ],
    },
  ];

  const rows = customers;

  return (
    <div style={{ width: '98%', margin: '0 auto' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        loading={loading}
        isRowSelectable={() => false}
        autoHeight
      />
    </div>
  );
};

export default CustomersTable;

type CustomersTableProps = {
  customers: Array<Customer>;
  loading: boolean;
  handleEditProfile: (customerId: string) => void;
  handleEditSubscription: (customerId: string) => void;
  handleCancelOrResumeSubscription: (customerId: string) => void;
};
